.wc-block-components-partial-payment {
    flex-direction: column;
    position: relative;

    .wc-block-components-partial-payment_form {
        display: flex;
        margin-bottom: 0;
        width: 100%;

        .wc-block-components-partial-payment_input {
            flex-grow: 1;
            margin-bottom: 0;
            margin-top: 0;
        }

        .wc-block-components-partial-payment_button {
            flex-shrink: 0;
            height: 3em;
            margin-left: 8px;
            padding-left: 24px;
            padding-right: 24px;
            white-space: nowrap;
        }
    }
}